window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("jquery-ui-dist/jquery-ui");

require("mdbootstrap/popper.min");
require("mdbootstrap/bootstrap");
require("mdbootstrap/mdb");

require("customer/_customer");
require("customer/customer.base");
require("customer/customer.ready");