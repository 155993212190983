customer.base = {
    jsonErrors: function (xhr, evt, status, form) {
        var content, value, _i, _len, _ref, $this;
        $this = form;
        content = $this.find('#errors');
        content.find('ul').empty();
        _ref = $.parseJSON(xhr.responseText).errors;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            value = _ref[_i];
            content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
        }
    },

    mdbootstrap: function () {
        $('.mdb-select').materialSelect();
        $(".button-collapse").sideNav();
        $('.field_with_errors + label').addClass('active');
        $('.mdb-select').click(e => e.stopPropagation());

        $('.table-responsive').on('shown.bs.dropdown', function (e) {
            var t = $(this),
                m = $(e.target).find('.dropdown-menu'),
                tb = t.offset().top + t.height(),
                mb = m.offset().top + m.outerHeight(true),
                d = 20; // Space for shadow + scrollbar.
            if (t[0].scrollWidth > t.innerWidth()) {
                if (mb + d > tb) {
                    t.css('padding-bottom', ((mb + d) - tb));
                }
            } else {
                t.css('overflow', 'visible');
            }
        }).on('hidden.bs.dropdown', function () {
            $(this).css({
                'padding-bottom': '',
                'overflow': ''
            });
        });

        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    },

    openModal: function (target) {
        $(target).modal(
            {
                backdrop: 'static',
                keyboard: false
            });
    }
}